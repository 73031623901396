export default {
	name: 'ReassignedUserHistoryDialog',

	data() {
		return {
			headerAdditionalUser: [
				{ text: 'Nombre de usuario', sortable: false, value: 'user_id.fullName' },
				{ text: 'Email', sortable: false, value: 'user_id.email' },
				{ text: 'Estado', sortable: false, value: 'status' }
			],
		};
	},

	props: {
		value: Boolean,
		item: Object,
	},

	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},

		formattedReassignments() {
			if (!this.item?.ticket_reassignments_history) return [];

			return this.item.ticket_reassignments_history.map((reassignment, index) => ({
				...reassignment,
				status: index === 0 ? 'Actual' : 'Histórico',
				statusColor: index === 0 ? 'green' : 'grey'
			}));
		},
	},

	methods: {
		close() {
			this.dialog = false;
		},
	},
};