export default {
	props: {
		value: Boolean,
	},
	data() {
		return {
			dialog: this.value,
			selectedAttentionType: 'VIRTUAL',
			attentionTypes: ['VIRTUAL', 'PRESENCIAL'],
		};
	},
	watch: {
		value(newVal) {
			this.dialog = newVal;
		},
		dialog(newVal) {
			this.$emit('input', newVal);
		},
	},
	methods: {
		confirmSelection() {
			this.$emit('start-ticket', this.selectedAttentionType);
			this.dialog = false;
		},
	},
};
